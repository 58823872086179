<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
  <el-form :model="form">
    <!-- <el-form-item label="客戶名稱:" :label-width="formLabelWidth">
      <el-select v-model="form.clientUserId" clearable placeholder="">
          <el-option width=120
            v-for="item in clientUsers"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>   
    </el-form-item> -->
    note:如果會員沒有先註冊則平台會自動建立一筆會員  會員手機和會員名稱依照現在填寫的為主
    <el-row :gutter="10">
      <el-col :span="4">
        <el-form-item label="會員帳號(身份證):" >
          <el-input type="text" @change="check_num(form.clientUserId)" v-model="form.clientUserId" @blur="checkClientUserId()">         
            
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="駕照類型:">
          <el-select v-model="form.motoLicense">
            <el-option width=120  key="M" label="重型機車" value="M"></el-option>
            <el-option width=120  key="S" label="輕型機車" value="S"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="4">
        <el-form-item label="會員手機(代填使用):" >
          <el-input type="text" v-model="form.phone" @blur="checkClientUserPhone()"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="會員名稱(代填使用):">
          <el-input type="text" v-model="form.clientUserDisName" @blur="checkClientUserDisName()"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="會員喜好:">
          {{form.adminNote}}
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="員工訂單備註:">
          <el-input type="text" v-model="form.reserveNote"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="訂單管道:" :label-width="formLabelWidth">
      <el-select v-model="form.clientType" placeholder="">
          <el-option width=120
            v-for="item in clientTypes"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>   
    </el-form-item>
    <el-form-item label="查詢區域:" :label-width="formLabelWidth">
      <el-select v-model="form.areaId" clearable placeholder="">
          <el-option width=120
            v-for="item in areas"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>   
    </el-form-item>

    <el-form-item label="查詢取車車行" :label-width="formLabelWidth">
        <el-select v-model="form.storeId" placeholder="" @change="getStoreCats()">
          <el-option width=120
            v-for="item in stores"
            v-show="item.areaId == form.areaId || form.areaId == ''"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>      
    </el-form-item>  
    <el-form-item label="查詢可預約(起):" :label-width="formLabelWidth">
        <el-col :span="7">
          <el-date-picker
          :clearable="false"
          :editable="false"
            v-model="form.sDateDate"
            type="date"
            placeholder="" @change="setForceGetPrice()">
          </el-date-picker>      
        </el-col>
        <el-col :span="6">
          <el-time-select
          :clearable="false"
          :editable="false"
            v-model="form.sDateHour"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            placeholder="" @change="setForceGetPrice()">
          </el-time-select>
        </el-col>      
    </el-form-item>
    <el-form-item label="查詢可預約(訖):" :label-width="formLabelWidth">
        <el-col :span="7">
          <el-date-picker
          :clearable="false"
          :editable="false"
            v-model="form.eDateDate"
            type="date"
            placeholder="" @change="setForceGetPrice()">
          </el-date-picker>      
        </el-col>
        <el-col :span="6">
          <el-time-select
          :clearable="false"
          :editable="false"
            v-model="form.eDateHour"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            placeholder="" @change="setForceGetPrice()">
          </el-time-select>
        </el-col>
    </el-form-item>
    <el-form-item label="查詢車型" :label-width="formLabelWidth">
        <el-select v-model="form.catId" clearable placeholder="">
          <el-option width=120
            v-for="item in cats"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>      
    </el-form-item>  

    <el-form-item label="" :label-width="formLabelWidth">
      <el-button  @click="getEmptyCats" type="primary" size="small">查詢可用車類</el-button>
    </el-form-item>


    <el-form-item  label="可預約價位" :label-width="formLabelWidth">
      {{emptySize}}
        <el-select v-model="form.emptyCat" clearable placeholder="" style="width:300px;">
          <el-option width=350
            v-for="item in emptyCats"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>      
    </el-form-item>
    <div v-for="(stock) in prodStocks" :key="stock.prodStockRowId" style="width:232.4px; border-style:solid; margin-top:5px;" 
    @click="selectStock(stock)" :id="'div_'+stock.prodStockRowId">
      <img style="width:232.4px; height:216.4px;" :src="stock.pic"/><br/>
      {{stock.descTitle}}
    </div>
    <el-form-item label="長租:" :label-width="formLabelWidth">
      <el-switch v-model="form.rentDateType" active-color="#13ce66" inactive-color="#ff4949" 
      active-value="2" inactive-value="1" @change="switchRentDateType">
      </el-switch>
    </el-form-item>
    <el-form v-if="isLongRent===false">
      <el-form-item label="" :label-width="formLabelWidth">
        <el-button  @click="getPrice" type="primary" size="small" :loading="isCal">{{isCal?'金額計算中':'計算金額'}}</el-button>
      </el-form-item>
      <el-form-item label="費用明細" :label-width="formLabelWidth">
        <div style="font-size:150%;">&emsp;費用小計:<span style="margin-left:20px;">{{this.form.price}}元</span></div>
        <div v-for="(priceDetail,index) in form.priceList" :key="index" style="font-size:150%">
          <span v-show="index === 1" style="margin-left:20px;"></span>
          {{priceDetail.name}}
          <span :class="priceDetail.class" style="">{{priceDetail.amt}}元</span>
        </div>
      </el-form-item>    
      <el-form-item label="預估金額" :label-width="formLabelWidth">
        <span style="font-size:20px;">{{form.detailPrice}}</span>
      </el-form-item>
    </el-form>
    <el-row  v-if="isLongRent===true" >
      <el-col :span="10">
      <el-form-item label="金額" :label-width="formLabelWidth">
        <el-input type="text" v-model="form.detailPrice"></el-input>
      </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="預約數量" :label-width="formLabelWidth">
        <el-select v-model="form.qty" clearable placeholder="">
          <el-option width=120
            v-for="item in ableStockNum"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>      
    </el-form-item>
      <el-form-item label="" :label-width="formLabelWidth">
        <el-button  @click="createOrder" type="primary" size="small">建立預約單</el-button>
      </el-form-item>
  </el-form>
    
    </el-card>
   
  </div>
</template>


<script>
export default {
  data () {
    return {
      fullscreenLoading: false,
      pageTotal:0,
      pageCurrent:1,
        qClientUserId:'',qClientOrderId:'',qPhone:'',qStoreId:'',
        emptySize:'',
        sysTime:'',
        prodStocks:[],
        form: {
          clientUserId:'',
          clientUserDisName:'',
          adminNote:'',
          phone:'',
          areaId:'',
          carNo:'',
          storeId:'',
          sDateDate:'',
          sDateHour:'',
          eDateDate:'',
          eDateHour:'',
          name: '',
          region: '',
          date1: '',
          date2: '',
          type: [],
          resource: '',
          desc: '',
          plusInsurance:'',
          detailPrice:0,
          emptyCat:'',
          clientType:null,
          priceList:[],
          reserveNote:'',
          rentDateType:1,
          motoLicense: 'M',
          qty: 1
        },
        formLabelWidth: '139px',
        clientTypes:[],
        stores:[],
        cats:[],
        areas:[],
        emptyCats:[],
        clientUsers:[],      
        isLongRent:false,
        isCreate:false,
        isCal:false,
        ableStockNum: [],
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          res.data.authStores.forEach(e => {
            this.stores.push(e)
          })   
          this.form.storeId = this.stores[0].code
          this.$store.dispatch('login',res.data)
          this.$axios.get('../api/booking/getSysTime',{})
            .then((res)=>{
              this.sysTime=res.data
              this.init()
          })
          this.getStoreCats()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }
      });   

    
  },
  name: 'Booking',
  components: {
    
  },
  computed:{
  },
  methods :{   
    getStoreCats(){
      this.$axios.get('../api/booking/getStoreCats?storeId='+this.form.storeId,{})
        .then((res)=>{
          this.cats=res.data  
        })
    } , 
    check_num (clientUserId) { // 限制輸入數字英文
      this.form.clientUserId = clientUserId.replace(/[^a-z/A-Z/0-9]/,'');
    },
    checkClientUserPhone(){
      if(this.form.clientUserId=='' ||this.form.clientUserId== null) {
        this.$axios
        .get('../api/booking/checkClientUserPhone?phone='+this.form.phone,{})
        .then((res)=>{
          if (res.data.clientUserId == undefined){
            this.$alert('沒有註冊過', '結果', { confirmButtonText: '確定' });
            this.form.clientUserId='';
            this.form.clientUserDisName='';
            this.form.adminNote='';
            this.form.motoLicense = 'S'
          } else {
            if(res.data.ban != 'T') {
              this.form.clientUserDisName=res.data.disName;
              this.form.clientUserId=res.data.clientUserId;
              this.form.adminNote=res.data.adminNote;
              this.form.motoLicense = res.data.motoLicense
            } else {
              this.$alert('該帳號已被停權暫時無法租借與預約,詳細請聯繫客服','結果',{confirmButtonText: '確定'})
              this.form.clientUserId = ''
            }
          }
        })
      }
      this.form.phone = this.form.phone.trim()
      if (/[~`!#$%&*+=\-\\';,/{}|\\":<>?]/g.test(this.form.phone)) {
        this.$alert("手機欄位請不要輸入特殊符號", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (/[^\d]/.test(this.form.phone)) { 
        this.$alert("手機欄位請輸入數字", "結果", {confirmButtonText: "確定"});
        return;
      }
    },
    checkClientUserId(){
      this.form.clientUserId = this.form.clientUserId.toUpperCase()
      if(this.form.clientUserId=='' || this.form.clientUserId==null){
        this.$alert('請輸入有效會員帳號', '結果', { confirmButtonText: '確定' });
        return
      }
      this.$axios
        .get('../api/booking/checkClientUserId?clientUserId='+this.form.clientUserId,{})
        .then((res)=>{
          if(res.data.disName==undefined){
            this.$alert('沒有註冊過', '結果', { confirmButtonText: '確定' });
            this.form.clientUserDisName='';
            this.form.phone='';
            this.form.adminNote='';
            this.form.motoLicense = 'S'
          }else{
            if(res.data.ban != 'T') {
              this.form.clientUserDisName=res.data.disName;
              this.form.phone=res.data.phone;
              this.form.adminNote=res.data.adminNote;
              this.form.motoLicense = res.data.motoLicense
            } else {
              this.$alert('該帳號已被停權暫時無法租借與預約,詳細請聯繫客服','結果',{confirmButtonText: '確定'})
              this.form.clientUserId = ''
            }
          }
      }).catch(()=>{

      });      
    },
    checkClientUserDisName(){
      this.form.clientUserDisName = this.form.clientUserDisName.trim()
      if (/[~`!#$%&*+=\-\\';,/{}|\\":<>?]/g.test(this.form.clientUserDisName)) {
        this.$alert("請不要輸入特殊符號", "結果", {confirmButtonText: "確定"});
        return;
      }
    },
    init(){
      let time=Date.parse(this.sysTime)
      let d=new Date()
      d.setTime(time)
      let min=d.getMinutes()>30 ?'30':'00';
      let h=d.getHours()>=10?d.getHours().toString():'0'+ d.getHours().toString();
      this.form.sDateDate=d;
      this.form.sDateHour=h+':'+ min;

      d=new Date(d.getTime()+24*1000*3600);
      this.form.eDateDate=d;
      this.form.eDateHour=h+':'+ min;
      // this.$axios
      //   .get('../api/register/getUsers',{})
      //   .then((res)=>{
      //     res.data.forEach((e)=>{
      //       this.clientUsers.push({code:e.clientUserId,disName:e.disName})
      //     });
      // }).catch(()=>{
      // });
      let codes=this.$clientType_combobox();
      codes.forEach(e=>{
        this.clientTypes.push(e)
      });
      this.form.clientType=codes[0].code
      // this.form.clientType=this.clientTypes[0].code
      this.$store.state.areas.forEach(e=>{
        this.areas.push({code:e.code,disName:e.disName})
      })
      this.form.areaId= this.$store.state.areas[0].code  
    },
    get14D(date,hour){
      let eDate=date;
      let str=eDate.getFullYear().toString()
      let month=(eDate.getMonth()+1).toString();
      if(month.length==1)
        month='0'+month;
      let day=(eDate.getDate()).toString();
      if(day.length==1)
        day='0'+day;

      str=str+month+day;
      str=str+hour
      return str;
    },
    getEmptyCats(){
      let s_str=this.get14D(this.form.sDateDate,this.form.sDateHour);
      let str=this.get14D(this.form.eDateDate,this.form.eDateHour);
      if ( s_str >= str ) {
        this.$alert('起始時間不可大於結束時間', '結果', { confirmButtonText: '確定' });
        return
      }
      this.form.emptyCat=null;
      let param={
          // areaId:this.form.areaId,
          storeId:this.form.storeId,
          catId:this.form.catId,
          sd:s_str,
          ed:str};
          //storeId先不用
      this.$axios
            .get('../api/clientOrder/getEmptyCats',{params:param})
            .then((res)=>{
              this.emptyCats.splice(0, this.emptyCats.length)
              this.prodStocks.splice(0, this.prodStocks.length)
              res.data.forEach(e=>{
                if(e.stockQty >= e.bookingQty){
                  let code=e.prodStockRowId;
                  // let catDisName=this.$catDisName(this.$store.state.cats,e.localCatId)
                  if(e.pic !== undefined & e.pic !== null) {
                    e.pic= '/imgs/zocha/' + e.pic
                  } else {
                    e.pic= '/imgs/zocha/fontProd/none_fontProdPhoto.png'
                  }
                  this.prodStocks.push(e)
                  this.emptyCats.push({code:code,
                    disName:   this.$storeDisName(this.$store.state.stores,e.localStoreId)+'->'+e.disName+'->'+e.descTitle});
                }
              });
              this.emptySize='共有'+this.emptyCats.length+"款車可用";
          }).catch((res)=>{
              console.log(res)
              this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
          });   
    },
    getPrice(){
      if (this.form.emptyCat==null || this.form.emptyCat.length == 0) {
        this.$alert('請選擇可用車款', '結果', { confirmButtonText: '確定' });
        return
      }

      this.form.priceList.splice(0, this.form.priceList.length)
      let eDate=this.get14D(this.form.eDateDate,this.form.eDateHour);
      let sDate=this.get14D(this.form.sDateDate,this.form.sDateHour);
      if ( sDate >= eDate ) {
        this.$alert('起始時間不可大於結束時間', '結果', { confirmButtonText: '確定' });
        return
      }
      sDate = sDate.replaceAll(/\D/g, '') + '00'
      eDate = eDate.replaceAll(/\D/g, '') + '00'
      this.isCal=true
      let param={
                prodStockId: this.form.emptyCat,
                sDate: sDate,
                eDate: eDate };
      this.$axios
        .get('../api/bookingStatus/getClientOrderPriceForBooking', { params: param })
        .then((res)=>{
          this.isCal=false
          if (res.data.rs === 'success') {
            let ps = res.data.priceList
            this.form.price = 0
            this.form.deposit = 0
            for (var key in ps) {
              if (key === 'rideHour') {
                this.rideHour = ps[key]
              } else {
                if (key === 'ridePrice') {
                  // ps[key] = 3
                  this.form.price = this.form.price + ps[key]
                  this.costPrice = this.costPrice + ps[key]
                  this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                  // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                  if (res.data.additionNote !== undefined) {
                    this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                  }
                }
              }
            }
            this.form.detailPrice = this.form.price
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        }).catch(()=>{
            this.isCal=false
            this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
        });      
    },
    createOrder(){
      if(this.form.clientUserId=='' || this.form.clientUserId == null){
        this.$alert('請輸入會員帳號', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.form.phone == "" || this.form.phone == null || this.form.phone == undefined) {
        this.$alert("手機號碼不能為空", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (this.form.clientUserDisName == "" || this.form.clientUserDisName == null || this.form.phone == undefined) {
        this.$alert("會員名稱不能為空", "結果", {confirmButtonText: "確定"});
        return;
      }
      if(this.form.emptyCat==null || this.form.emptyCat.length==0){
        this.$alert('請選擇可用車款', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.detailPrice<=0){
        this.$alert('金額不對無法新增訂單', '結果', { confirmButtonText: '確定' });
        return
      }
      this.fullscreenLoading = true
      let ed=this.get14D(this.form.eDateDate,this.form.eDateHour);
      let sd=this.get14D(this.form.sDateDate,this.form.sDateHour);
      let pa={
        areaId:this.form.areaId,
        clientUserId:this.form.clientUserId.toUpperCase(),
        sd:sd,
        ed:ed,
        price:this.form.detailPrice,
        prodStockRowId:this.form.emptyCat,
        clientUserDisName:this.form.clientUserDisName,
        phone:this.form.phone,
        clientType:this.form.clientType,
        reserveNote:this.form.reserveNote,
        rentDateType:this.form.rentDateType,
        motoLicense: this.form.motoLicense
      };
      if (this.form.qty == 1) {
        this.$axios.get('../api/clientOrder/checkClientIsRent',{params:pa}).then(rs =>{
          let message = '是否確定新增訂單'
          if (rs.data.rs != 'success') {
            message = rs.data.rsMsg
          } 
        this.$confirm(message,'注意',{
          confirmButtonText:'確定',
          cancelButtonText:'取消'
        }).then(() => {
          this.isCreate = true
          this.$axios.post('../api/clientOrder/createClientOrderForApi',pa).then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.$alert('新增完成', '結果', {confirmButtonText: '確定',
                callback: () => {
                  location.reload()
                }
              });
            } else {
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }
          }).catch(()=>{
            this.fullscreenLoading = false
            this.$alert('新增失敗', '結果', {confirmButtonText: '確定',
              callback: () => {
                location.reload()
              }
            })
          })
        }).catch(()=>{
          this.fullscreenLoading = false
        })
        })
      } else {
        this.$axios.get('../api/clientOrder/checkClientIsRent',{params:pa}).then(rs =>{
          let message = '是否確定新增'+this.form.qty+'張訂單'
          if (rs.data.rs != 'success') {
            message = rs.data.rsMsg
          } 
          this.$confirm(message,'注意',{
            confirmButtonText:'確定',
            cancelButtonText:'取消'
          }).then(() => {
            this.isCreate=true
            for(let i =1 ;i <= this.form.qty; i++) {
              this.$axios.post('../api/clientOrder/createClientOrderForApi',pa).then((res)=>{
                 if (res.data.rs !== 'success') {
                   this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
                   return
                 }
              }).catch(()=>{
                  this.fullscreenLoading = false
                    this.$alert('新增失敗', '結果', {confirmButtonText: '確定',
                      callback: () => {
                        location.reload()
                      }
                    });
              });  
            }
            this.fullscreenLoading = false
            this.$alert('新增完成', '結果', {confirmButtonText: '確定',
              callback: () => { location.reload() }
            });
          }).catch(()=>{
            this.fullscreenLoading = false
          })
        }) 
      }
    },
    select(id){
      this.form.emptyCat=id
    },
    selectStock(stock) {
      this.form.emptyCat=stock.prodStockRowId
      this.ableStockNum = []
      let maxAbleStock = parseInt(stock.stockQty) - (stock.bookingQty == null ? 0 : parseInt(stock.bookingQty))
      for(let i = 1 ; i <= maxAbleStock; i++) {
        this.ableStockNum.push(i)
      }
    },
    switchRentDateType(){
      if(this.form.rentDateType ==2){
        this.isLongRent=true
      }else{
        this.isLongRent=false
        }

    },
    setForceGetPrice(){
      this.prodStocks.splice(0, this.prodStocks.length)
      this.form.priceList.splice(0,this.form.priceList.length)
      this.form.detailPrice=null
    },
  }
}
</script>
