import { render, staticRenderFns } from "./AdminQuickGet.vue?vue&type=template&id=38594d7e&scoped=true&"
import script from "./AdminQuickGet.vue?vue&type=script&lang=js&"
export * from "./AdminQuickGet.vue?vue&type=script&lang=js&"
import style0 from "./AdminQuickGet.vue?vue&type=style&index=0&id=38594d7e&scoped=true&lang=css&"
import style1 from "./AdminQuickGet.vue?vue&type=style&index=1&id=38594d7e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38594d7e",
  null
  
)

export default component.exports